/* footer  */

footer {
    position: fixed;
    margin: 0;
    bottom: 0;
    height: 25px;
    width: 100%;
    border-top: 1px solid #1d3e4d;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--hf-background);
    font-size: 0.7em;
    color: var(--footer-a);
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
  }
  footer a {
    color: var(--footer-a);
  }
  footer p {
    margin: 2px 20px 0;
  }
  
  footer span {
    margin-right: 20px;
    float: right;
  }
  footer:hover {
    border-top: 1px solid var(--hover-border);
  }