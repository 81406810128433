:root {
  --body-font: #ffffff;
  --terminal-background: #000000;
  --hover-background: #000000;
  --hf-background: #262e36;
  --body: #182027;
  --blue-border: #1d3e4d;
  --link-hover: #2384d3;
  --hover-dark: #11171b;
  --hover-border: #0adf03a4;
  --terminal-text: #09ff00;
  --footer-a: #a9a9aa;
  --site-hover: #1e4a6ea6;
}

.light-mode {
  --body-font: #000000;
  --terminal-background: #fff7dc;
  --hover-background: #f6b3b344;
  --hf-background: #f4bfbf;
  --body: #dfdfdf;
  --hover-dark: #f6f6f6;
  --hover-border: #f6b3b3;
  --terminal-text: #0b2208;
  --footer-a: #000000;
  --site-hover: #a2cae667;
}

body {
  cursor: url(./assets/cursors/default.png) 2 0, auto;
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  background-color: var(--body);
  color: var(--body-font);
  font-size: 20px;
  transition: 500ms;
  overscroll-behavior-y: none;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: var(--body-font);
  transition: 500ms;
  cursor: url(./assets/cursors/pointer.png), default !important;
}
a:hover {
  color: var(--link-hover);
}

ul {
  position: relative;
  font-size: 0.85em;
}