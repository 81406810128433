.heading {
    background-color: var(--hf-background);
    z-index: 1;
    position: fixed;
    display: block;
    width: 100%;
    padding: 10px 0 10px 0;
    border-bottom: 2px solid var(--blue-border);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .heading:hover .nav-container {
    border-bottom: 2px solid var(--hover-border);
  }
  .heading h3 {
    text-align: center;
    margin: 0;
  }

  .switch-container {
    position: absolute;
    right: 3%;
    z-index: 1;
    top: 20%;
  }
  .switch {
    cursor: url(../../assets/cursors/pointer.png), default !important;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 11px;
    padding-bottom: 3px;
  }
  
  .switch-container img{
    margin-bottom: 5px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #008616;
    background-color: #000000;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
  }
  .slider-container:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    background-color: #131c2b;
    border: 1px solid #008616;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
  }

  input:checked + .slider-container {
    background-color: #ffffff;
    border: 1px solid #07202e;
  }
  
  input:checked + .slider-container:before {
    background-color: #62aae6;
    border: 1px solid #07202e;
    transform: translateX(16px);
  }

  /* Rounded sliders */
.slider-container.round {
    border-radius: 35px;
  }
  
  .slider-container.round:before {
    border-radius: 50%;
  }

.switch-container img {
  width: 25px;
  height: 25px;
  margin: 5px;
}