.outer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .inner {
    background-color: var(--body);
    padding: 2%;
    border: 2px solid var(--blue-border);
    border-radius: 10px;
    max-width: 900px;
    width: 100%;
    margin: 20px 0 20px 0;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
  }
  .one {
    margin-top: 70px;
  }
  .one:hover {
    background-color: var(--hover-dark);
    border: 2px solid var(--hover-border);
  }
  .two:hover {
    background-color: var(--hover-dark);
    border: 2px solid var(--hover-border);
  }
  
  .code {
    font-family: "Share Tech Mono", monospace;
    color: var(--terminal-text);
    border: 2px solid var(--blue-border);
    background-color: var(--hover-background);
    position: absolute;
    right: 1%;
    width: 100px;
    text-align: center;
    border-radius: 20px;
    font-size: 0.75em;
    padding: 5px 0 3px 0;
  }

   .code:hover {
    color: var(--terminal-text);
   }

  /* School & Work Info Positioning */
  .inner h4 {
    text-align: center;
    margin-top: 0;
    margin: 0;
  }
  
  /* 'Education' 'Experience' headers  */
  .inner h5 {
      margin: 10px 0 10px 0;
      font-size: .9em;
      border-bottom: 3px double var(--blue-border);
      border-radius: 3px;
      display: inline-block;
      padding: 8px 25px 8px 25px;
  }
  
  /* School/Work span headings */
  .inner div {
    position: relative;
    margin-top: 15px;
  }
  
  .h-span {
    margin: 0 3% 0 40px;
    font-size: .9em;
  }
  .h-span i {
    font-size: .85em;
  }
  .inner div span {
    float: right;
    margin-left: 10px;
    font-size: .85em;
  }
  
  .inner i {
    margin-top: 0;
  }
  
  /* Lists */
  .inner ul {
    margin-top: 12px;
    padding-left: 5%;
  }

  li {
    color: var(--blue-border);
    padding-left: .2rem;
    margin-bottom: 2px;
    margin-right: 25%;
    font-size: .8em;
  }
  li span {
    color: var(--body-font);
  }
  
  /* wake bitly logo  */
  .logo {
    position: absolute;
    padding: 3px;
    left: -2%;
    border-radius: 50%;
  }
  .two{
    padding-bottom: 20px;
    margin-top: 50px;
    margin-bottom: 90px;
  }
  

  .lang-container{
    display: flex;
    justify-content: space-evenly;
    font-size: .9em;
    margin-top: 10px;
  }

  .lp{
    margin: 0;
    padding: 5px 10px 5px 10px;
    border: 1px solid var(--blue-border);
    border-radius: 20px;
    font-size: 0.75em;
    text-align: center;
    margin: auto 5px auto;
    word-wrap: unset;
  }
  
.gen {
  height: 2.5em;
  display: flex;
  align-items: center;
}

  .language-tools {
    margin-right: 15px;
  }
  .skill-gen{
    margin-left: 10px;
  }
  .skill-profile{
    margin: 5px;
    font-size: .9em;
  }


  @media (max-width: 497px) {
    li {
      font-size: .75em;
    }
    .h-span {
      font-size: .8em;
    }
    .code {
      font-size: .65em;
      width: 75px;
    }
    .lp {
      font-size: .65em;
    }
    .inner h5{
      font-size: .7em;
    }
  } 