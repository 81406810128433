
/* Projects  */
#projects {
    margin-bottom: 30px;
    padding-top: 0;
  }
  
  .row {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .box {
    border: 2px solid var(--blue-border);
    margin-left: 5px;
    margin-right: 5px;
    width: 310px;
    height: 330px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    position: relative;
    overflow: hidden;
  }
  
  .box:hover {
    border: 2px solid var(--link-hover);
  }
  
  .site-link {
    background-size: cover;
    height: 40px;
    position: absolute;
    width: 40px;
    left: 5%;
    border-radius: 10px;
  }
  .r-header{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .r-link{
    height: 20px;
    width: 20px;
    position: relative;
    left: 1%;
    border-radius: 0;
  }
  
  .box p {
    border-radius: 5px;
    margin-top: 0;
    padding: 7px;
    height: 130px;
  }
  
  .p_img {
    height: 100px;
  }
  
  /* Language containers */
  .lang {
    display: flex;
    justify-content: space-around;
  }
  
  .lang a {
    font-family: "Share Tech Mono", monospace;
    color: var(--link-hover);
    width: 100px;
    border: 2px solid transparent;
    border-radius: 30px;
    margin: 37px 5px 0 5px;
    padding: 5px 0 3px 0;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
  }
  
  .two:hover .lang a {
    color: var(--terminal-text);
    border: 2px solid var(--blue-border);
    background-color: var(--hover-background);
  }
  
  /* github animation */
  
  .git {
    /* background-image: url(images/git.png); */
    background-size: cover;
    width: 45px;
    height: 45px;
    position: absolute;
    left: 330px;
    bottom: 345px;
    margin-top: 0px;
    border-radius: 10px;
    -webkit-transition: 800ms;
    -moz-transition: 800ms;
    -ms-transition: 800ms;
    -o-transition: 800ms;
    transition: 800ms;
  }
  .box:hover .git {
    transform: translateY(50px) translateX(-55px);
  }
  
  .more {
    margin-bottom: 0;
    text-align: center;
    font-size: .75em;
  }
  .in-link {
    color: var(--body-font);
    border-bottom: 1px solid transparent;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
  }
  .in-link:hover {
    border-bottom: 1px solid var(--hover-border);
    color: var(--body-font);
    font-style: italic;
  }

  @media only screen and (max-device-width: 800px)  {
    .git {
      left: 275px;
      bottom: 295px;
    }
    .box:hover .git {
      transform: none;
    }
  }