/* Top Background */

.about-container {
    padding: 120px 0 0 0;
    background-color: var(--body);
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
  }
  
  /* Terminal Window */
  .terminal-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
  }
  .terminal {
    font-family: "Share Tech Mono", monospace;
    width: 500px;
    min-height: 500px;
    margin: 10px;
    color: var(--terminal-text);
    background-color: var(--terminal-background);
    font-size: 15px;
    border-radius: 8px;
    padding-bottom: 15px;
    border: 1px solid var(--blue-border);
  }
  
  .terminal-container i {
    margin: 0 20px 0 45px;
    display: block;
  }
  .terminal-container a {
    color: var(--terminal-text);
    border-bottom: 1px solid transparent;
    transition: 0;
  }
  .terminal-container a:hover {
    border-bottom: 1px solid var(--terminal-text);
  }
  .terminal-container p {
    margin-bottom: 2px;
    margin-left: 25px;
  }
  
  .t-head-container {
    display: flex;
    height: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgba(58, 57, 57, 0.5);
    margin-bottom: 20px;
    border-bottom: 1px solid var(--blue-border);
  }
  
  .t-head-container div {
    margin: 3px 3px 3px 5px;
    border-radius: 50%;
    width: 15px;
    text-align: center;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -ms-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
  }
  
  .t-head-container div:nth-child(1) {
    background-color: #ff0000;
  }
  .t-head-container div:nth-child(1):hover {
    background-color: #a10303;
  }
  .t-head-container div:nth-child(2) {
    background-color: #ffd000;
  }
  .t-head-container div:nth-child(2):hover {
    background-color: #bb9904;
  }
  .t-head-container div:nth-child(3) {
    background-color: #07c700;
  }
  .t-head-container div:nth-child(3):hover {
    background-color: #078e02;
  }
  